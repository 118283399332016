.modal-trigger {
	display: flex;
	justify-content: center;
}

.modal-trigger > .btn {
	width: 300px;
	height: 50px;
	border-radius: 0;
	font-family: MabryPro;
	border: solid 2px #000000;
	box-shadow: calc(0.1em + 1px) calc(0.1em + 1px) 0 #00000096;
	background: linear-gradient(to right, #f1c100, rgb(255, 229, 57));
}
.modal-trigger > .btn:hover {
	background: linear-gradient(to right, #fffdf7, rgb(255, 221, 0));
}

/* ----------------------------------------------------*/
@media screen and (max-width: 991px) {
	.modal-trigger > .btn {
		width: 200px;
		height: 40px;
	}
}
