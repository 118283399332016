@import url('https://fonts.googleapis.com/css2?family=Lexend+Mega:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap');
.banner-full-div {
	max-height: 100%;
	display: flex;
}

.banner-backdrop {
	/* height:80vh; */
	background: center/cover no-repeat;
	margin-bottom: 2rem;
	overflow: hidden;
	position: relative;
}
.banner-backdrop:before {
	content: '';
	background: linear-gradient(to right, black, transparent);
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	height: 100%;
	width: 100%;
}

.banner-movie-info {
	/* border: 4px solid #f83737; */
	display: flex;
	justify-content: center;
	font-family: Public Sans;
	padding: 5rem 1rem 1rem 1rem;
	z-index: 2;
	min-width: 50%;
}

.banner-movie-info > * {
	z-index: 1;
	/* flex-basis: auto; */
}
.banner-movie-info > div {
	margin: 0 1rem;
}
.banner-movie-info > .col-sm {
	max-width: 500px;
	padding-left: 0;
}

.banner-movie-info.row {
	/* title overview box */
	margin: 0;
	align-items: center;
	justify-content: center;
	position: relative;
}

.banner-movie-title {
	font-family: MabryPro;
	font-optical-sizing: auto;
	font-weight: 700;
	text-shadow: #0d0d0d 0.08em 0.08em 0;
	font-style: normal;
}
.banner-movie-genres {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
}
.banner-movie-genres .badge {
	font-size: 14px;
	padding: 0.5em;
	font-family: monospace;
	border-radius: 0;
	border: solid 1px #ffffff;
	box-shadow: calc(0.1em + 1px) calc(0.1em + 1px) 0 #00000096;
	background: linear-gradient(to right, #ae1d1d, transparent);
}

.banner-full-div > div > div > div:nth-child(1) > hr {
	width: 70%;
}

.banner-extra {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-self: center;
	margin: 0 !important;
	padding: 0 !important;
	height: 100%;
}
.banner-extra .row {
	margin: 10px;
}

.detail-trigger {
	display: flex;
	justify-content: center;
	color: #f1c100;
}

.detail-trigger > .btn {
	width: 300px;
	height: 50px;
	border-radius: 0;
	font-family: monospace;
	font-weight: bold;
	background-color: #000; /* Black */
	color: #fff; /* White text for contrast */
	border: solid 2px #ffffff;
	box-shadow: calc(0.1em + 1px) calc(0.1em + 1px) 0 #000000;
	z-index: 2;
}
.detail-trigger > .btn:hover {
	background: linear-gradient(to right, #000000, rgb(132, 0, 0));
	z-index: 2;
}

@media screen and (max-width: 768px) {
	.banner-movie-title {
		font-size: 2.5rem;
	}
	.banner-full-div > div > div > div:nth-child(1) > div:nth-child(4) {
		padding-top: 1rem;
	}
	.banner-movie-genres {
		justify-content: center;
	}
	.detail-trigger > .btn,
	.preview-trigger > .modal-trigger > .btn {
		width: 250px;
		height: 55px;
		font-size: 1rem;
		font-weight: 600;
		font-family: MabryPro;
	}
	.detail-trigger > .btn {
		border-color: rgb(204, 204, 204);
		background-color: rgb(16, 16, 16);
	}
}
