.spinner-area {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}
.alert-area {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}
