.modal {
	--bs-modal-border-radius: 0 !important;
}
/* .modal-content{
    background-color: black  !important;
    background-image: linear-gradient(135deg, #000000 0%, #414141 74%) !important;
} */
.modal-footer button {
	--bs-btn-border-radius: 0 !important;
}

.modal-content {
	background: transparent;
	border: none;
	padding: 0;
}

.modal-dialog {
	margin: 0;
	max-width: none;
	width: auto;
	height: auto;
}

.modal-body {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.youtube-video {
	width: 100%;
	height: 100%;
}

.modal-body > .close-btn {
	width: 640px;
	height: 50px;
	border-radius: 0;
	font-family: monospace;
	background-color: black;
	border: 2px solid white;
}

@media screen and (max-width: 768px) {
	/* .modal-dialog {
		position: relative;
	} */
}
