.profile-card {
	display: flex;
	align-items: center;
	color: white;
	background-color: none;
	border-radius: 8px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	padding: 16px;
	margin-bottom: 16px;
}
.profile-picture-container {
	width: 50px; /* Set the width */
	height: 50px; /* Set the height based on the 2:3 ratio */
	border-radius: 50%; /* Make it a circle */
	overflow: hidden; /* Hide any content that overflows the circle */
}
.profile-picture {
	width: 100%;
	height: 100%;
	object-fit: cover; /* Ensure the image covers the entire container */
}

.profile-card .details {
	flex: 1;
	padding-left: 1rem;
}
.profile-card h3 {
	font-size: 1rem;
	font-family: cursive;
}
.profile-card-name {
	font-size: medium;
	font-weight: bold;
	cursor: pointer;
}
.profile-card-name:hover {
	text-decoration: underline goldenrod;
}
.profile-card-role {
	margin-bottom: 4px;
	font-size: small;
	color: #00942f;
}
