.review-title {
	margin-top: 1rem;
	margin-left: 2rem;
	font-size: 24px;
	font-weight: 700;
	font-family: MabryPro;
}
.all-reviews-container,
.default-reviews-container {
	max-width: 80%;
}
.btn-box-shadow {
	box-shadow: 3px 5px #888888;
	/*box-shadow: 3px 5px #056dff;*/
}
.row > * {
	flex-shrink: inherit !important;
}

.showmore-div {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-top: 3px;
	margin-right: 0px;
}
.togglemore-div {
	display: flex;
	align-items: center;
	position: relative;
	justify-content: flex-end;
	margin: 2rem 0rem 2rem 0rem;
	max-width: 90%;
}
.togglemore-div > button {
	width: 200px;
	height: 40px;
	border-radius: 0;
	font-family: MabryPro;
	border: solid 2px #000000;
	box-shadow: calc(0.1em + 1px) calc(0.1em + 1px) 0 #00000096;
	background: linear-gradient(to right, #f1c100, rgb(255, 229, 57));
}

.togglemore-div > button:hover {
	background: linear-gradient(to right, #fffdf7, rgb(255, 221, 0));
	border-color: rgb(43, 43, 43) !important;
}

.reviewContent {
	margin-left: 0;
}

.review-box {
	margin-left: 0;
}
.review-box > div.row {
	margin: 0;
}
.all-reviews-container {
	margin: auto;
}

.reviewUser {
	margin-left: 0 !important;
	padding-bottom: 20px;
}
.reviewUser > div {
	display: flex;
	align-items: center;
	margin: 0;
	font-size: 1rem;
	font-family: monospace;
	font-weight: bold;
}
.reviewUser > div > img {
	justify-content: center;
	margin-right: 15px;
	width: 50px;
	border-radius: 50%;
	box-shadow: 4px 3px #e50914;
}
.reviewUser h6 {
	width: 80%;
}
.rating {
	margin: 10px;
}
.rating svg {
	color: goldenrod;
	font-size: 0.8rem;
}
.review-content {
	padding: 0px;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-style: italic;
	font-size: 16px;
}

/*-----------------------------------*/
@media screen and (max-width: 991px) {
	.review-title {
		padding-top: 2rem;
	}
	.all-reviews-container,
	.default-reviews-container {
		max-width: 100%;
	}
	.togglemore-div {
		max-width: 100%;
		justify-content: center;
	}
}
