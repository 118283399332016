.movie-card {
	height: 350px;
	box-sizing: content-box;
	background-size: cover;
	background-position: center;
	cursor: pointer;
	/* transition: 0.5s; */
	margin: 0.2rem;
}

.overlay {
	width: 100%;
	height: 100%;
	background: rgba(30, 28, 28, 0.9);
	opacity: 0;
	transition: all 0.5s;
	color: white;
	font-weight: bold;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}
.overlay:hover {
	transition: all 0.5s;
	opacity: 1;
}
.movie-card:hover {
	transform: scale(1.1) translateZ(17px);
	/* transition: all 0.5s; */
	border: 2px solid #e50914;
	z-index: 1;
	box-shadow: 10px 15px 2px #000000;
}
.movie-card-content {
	padding: 0 1rem 1rem 1rem;
}
.card-movie-title {
	font-family: MabryPro;
	font-size: 1.4rem;
	font-weight: 700;
	padding-top: 1rem;
}
.title-hr {
	width: 200px;
	height: 5px;
	color: #e50914;
	padding-bottom: 1rem;
	opacity: 1;
	margin: auto;
}
.card-genre-badges {
	display: flex;
	flex-wrap: wrap;
}
.genre-badge {
	margin: 0.2rem;
	font-family: monospace;
	font-size: 10px;
	font-weight: 400;
	padding: 0.25rem;
	border-radius: 0;
	border: solid 1px #ffffff;
	box-shadow: calc(0.1em + 1px) calc(0.1em + 1px) 0 #00000096;
	background: linear-gradient(to right, #ae1d1d, transparent);
}

.movie-card-detail {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	font-size: 13px;
	font-style: italic;
	padding-top: 1rem;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
		Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.movie-card-detail .vote {
	color: white;
	padding: 0rem;
}
.movie-card-detail .vote div {
	padding: 0.1rem;
}
.thumbs-up-votes svg {
	font-size: 14px;
	color: rgb(196, 196, 196);
}
.movie-card-detail .stars svg {
	font-size: 13px;
	color: goldenrod;
}
.popular svg {
	color: #e50914;
}
.bullseye svg {
	color: rgb(196, 196, 196);
}

.user-reactions {
	display: flex;
	background-color: rgba(30, 30, 30, 0.423);
	background-image: linear-gradient(
		180deg,
		rgba(0, 0, 0, 0.7) 20%,
		transparent
	);
	height: 0;
	width: 100%;
	margin-bottom: 2.2rem;
}
.user-reactions div {
	margin: auto;
}
.play-btn,
.add-btn,
.like-btn {
	color: whitesmoke;
	padding: 0.1rem;
}
.play-btn svg,
.add-btn svg,
.like-btn svg {
	font-size: 25px;
}
.play-btn svg:hover,
.add-btn svg:hover,
.like-btn svg:hover {
	color: #e50914;
}
