.movie-row-container {
	margin: 0 !important;
}
.movie-cards-container-row {
	padding-top: 3rem;
	margin: 0 !important;
}
.movie-cards-container-row.row > * {
	padding-right: 0 !important;
	padding-left: 0 !important;
}

/* ---------------------movies grid-------------------*/
.movies-grid.col-lg-9.col-12 > div > div {
	padding-right: 0;
	padding-left: 0;
}
/* ---------------------pagination-------------------*/
.pagination {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-top: 35px;
	background-color: none;
	--bs-pagination-bg: transparent;
	--bs-pagination-color: #fff;
	--bs-pagination-padding-y: 0.8rem;
	--bs-pagination-padding-x: 1.3rem;
	--bs-pagination-border-radius: 0;
	--bs-pagination-hover-color: #e50914;
	--bs-pagination-hover-bg: white;
	--bs-pagination-hover-font-weight: bold;
	--bs-pagination-active-border-color: white;
	--bs-pagination-active-bg: #e50914;
}
.page-item a:focus {
	font-weight: bold;
	box-shadow: none !important;
}
/* ----------------------------------------------------*/
/* ------(filters & all movies btn)left container------*/
.all-movie-btn {
	background-color: #e50914;
	color: antiquewhite;
	border: solid 2px antiquewhite;
	border-radius: 0;
	font-size: 1rem;
	font-weight: bold;
	margin-top: 1.5rem;
	margin-left: 1rem;
	padding: 0.5rem;
	width: 15rem;
}
.all-movie-btn:hover {
	background-color: antiquewhite;
	color: #e50914;
	border: solid 2px #e50914;
	border-radius: 0;
	font-size: 1rem;
	font-weight: bold;
}

/* ----------------------------------------------------*/
.filter-container {
	display: flex;
	flex-direction: column;
	position: relative;
	padding-top: 2rem;
}
.filter-box > .row {
	display: flex;
	margin: 0.5rem;
}
.filter-box {
	display: flex;
	flex-direction: column;
	position: relative;
	padding-top: 3rem;
	padding-left: 2rem;
	/* max-width: 450px;
	position: fixed; */
	left: 0;
}
.filter-results-of {
	font-size: 1rem;
	color: antiquewhite;
	font-weight: bold;
}
.filter-results-of > * .form-control {
	background-color: rgb(24, 24, 24);
	border-radius: 0;
	color: antiquewhite;
	cursor: grab;
}
.keyword-show {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 1rem;
	color: antiquewhite;
	font-weight: bold;
}
.keyword-show > span {
	color: #e50914;
	font-weight: bold;
}

.filter-results-of > * .form-control:hover {
	background-color: rgb(35, 28, 52);
	border-color: #e50914;
}

/* ----------------------------------------------------*/
.no-found-box {
	display: grid;
	font-family: fantasy;
	place-items: center;
	height: 50vh;
}
.no-search-results {
	display: flex;
	justify-content: center;
	align-items: center;
	color: #e50914;
	border-bottom: 1px solid #e50914;
}

/* ----------------------------------------------------*/

.spinner-area {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}
.alert-area {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

@media screen and (max-width: 991px) {
	.movie-cards-container-row {
		padding-top: 3rem;
	}
	.movie-cards-container-row.row > * {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.movies-grid.col-lg-9.col-12 > div > div {
		padding-right: 0;
		padding-left: 0;
	}
	.filter-container.col-lg-4.col-12 > div > div {
		padding-top: 2rem;
	}
	.all-movie-btn {
		margin-top: 2rem;
	}
	.filter-box {
		display: flex;
		flex-direction: column;
		position: relative;
		padding: 0;
	}

	.no-found-box {
		height: 40vh;
	}
	.no-search-results {
		font-size: 1.1rem;
	}
}

@media screen and (max-width: 1199px) {
	div.movie-card-content > hr {
		display: none;
	}
}
